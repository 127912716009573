.Footer {
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  background-color: #03477e;
}

/* Top */
.FooterTop {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
/* Column1 */
.FooterTCol1 {
  width: 20%;
  height: 100%;
}
.CompanyLogo {
  width: 100px;
  height: 100px;
}
.FooterTC1H {
  color: white;
  font-weight: bolder;
  font-size: x-large;
  font-family: Roboto;
}
.FooterTC1PRE {
  width: 100%;
  color: #ccc;
  text-wrap: pretty;
  font-family: Open Sans;
}

.FooterTCPRE {
  color: #ccc;
  font-family: Open Sans;
}
/* Column2 */
.FooterTCol2 {
  width: 15%;
  height: 100%;
  /* background-color: ; */
}
/* Column3 */
.FooterTCol3 {
  color: white;
  width: 15%;
  height: 100%;
}
/* Column4 */
.FooterTCol4 {
  color: white;
  width: 15%;
  height: 100%;
}
/* Column5 */
.FooterTCol5 {
  color: white;
  width: 15%;
  height: 100%;
}

/* FooterBottom */
.FooterBottom {
  color: white;
  background-color: orange;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.FooterBottom a {
  color: white;
}

.FooterSMediaIcons {
  width: auto;
  display: flex;
  flex-direction: row;
}
.FooterSMI {
  width: 2%;
  height: 25px;
}

/* copyrightDiv */
.copyrightDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: lightgray;
  color: black;
  border-bottom-color: black;
}
.copyrightContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.copyrightContent h3,
h4 {
  font-family: "Open Sans", sans-serif;
}

.developer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
/*  */
.floatDIV {
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 768px) {
  .CompanyLogo {
    display: none;
  }

  /* .floatDIV {
    display: none;
  } */

  .floatDIV {
    position: unset;
  }

  .FooterTCol1 {
    display: none;
  }

  .FooterTCol2 {
    display: none;
  }

  .FooterTCol4 {
    display: none;
  }

  /* .FooterTCol4 {
    color: white;
    width: auto;
    height: auto;
  } */

  .FooterTCol5 {
    display: none;
  }

  /* .FooterTC1PRE {
    display: none;
  } */

  /* .FooterBottom {
    display: none;
  } */

  .FooterTop {
    /* width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; */
    /* display: none;
    width: 100%;
    height: 200px;
    flex-direction: column;
    justify-content: space-around; */
  }

  /* .FooterBottom {
    height: 100px;
  } */

  .FooterTCol3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    flex-grow: 1;
    display: flex;
    /* background-color: orange; */
  }
}
