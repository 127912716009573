/* * {
  padding: 0;
  margin: 0;
}

.Team {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.TeamHead {
  width: 100%;
  height: 15vh;
  background-color: lightblue;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TeamHead h2 {
  color: white;
}

.TeamMembers {
  max-width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 20px;
}

.meamber-card {
  width: 250px;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .Team {
    display: none;
  }
  .TeamMembers {
    display: flex;
    flex-direction: column;
  }


} */

/* * {
  padding: 0;
  margin: 0;
} */

.Team {
  width: 100%;
  height: auto;
}

.TeamHead {
  max-width: 100%;
  height: 15vh;
  background-color: lightblue;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TeamHead h2 {
  color: white;
}

.TeamMembers {
  display: grid;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.TeamMember {
  margin: 15px;
}

.member-card {
  max-width: 300px;
}

@media only screen and (max-width: 768px) {
  .TeamMembers {
    grid-template-columns: 1fr; /* Change to single column layout */
  }
}
