* {
  padding: 0;
  margin: 0;
}

.Services {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*  */
.decreasing-width pre {
  width: 100%; /* Adjust width as needed */
  font-family: monospace; /* Use a monospace font for consistent width */
  overflow-x: hidden; /* Hide horizontal overflow */
}
.decreasing-width pre {
  white-space: pre-wrap; /* Preserve whitespace */
  line-height: 1.5; /* Adjust line height as needed */
  margin: 0; /* Remove default margin */
}
.decreasing-width pre span {
  display: inline-block;
  font-size: 20px; /* Initial font size */
  transition: font-size 0.3s ease-in-out; /* Smooth transition */
}
.decreasing-width pre:hover span {
  font-size: 10px; /* Decrease font size on hover */
}
/*  */
.divpre {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.servicepre {
  width: 80%;
  text-wrap: wrap;
  white-space: pre-wrap;
  text-align: center;
  padding: 15px;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'Open Sans', sans-serif;
  color: #5f6d7e;
  font-size: 16px;
}
/*  */

.ServiceHeader {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/bgimg1.jpg");

  /* Set a specific height */
  width: 100%;
  height: 200px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.ServiceHeader h1 {
  /* Place text in the middle of the image */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  /* font-family: "Courier New", Courier, monospace; */
  font-family: "Roboto", sans-serif;
}

.ServicesCards {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 25px;
}

@media only screen and (max-width: 768px) {
  .divpre {
    display: none;
  }

  .ServicesCards {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
