* {
  padding: 0;
  margin: 0;
}

.Brands {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.brand-header {
  text-align: center;
  margin-bottom: 20px;
}

.brand-header h1 {
  color: orange;
}

/* Styling for the marquee container */
.marquee-container {
  overflow: hidden;
}

.brand-img {
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin: 0 100px; /* Adjust spacing between images */
}

@media screen and (max-width: 768px) {
  .Brands {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
}
