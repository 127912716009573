.Contact {
  width: 100%;
  /* height: 90vh; */
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContactUS_RL {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.ContactRight {
  max-width: 50%;
}

.contactusImg {
  display: block;
  max-width: 100%;
  /* max-height: 250px; */
  height: auto;
}

/* .ContactLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  height: auto;
  background-color: lightblue;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
} */

.ContactLeft {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 20px;
}

.contactForm {
  width: 100%;
  justify-content: left;
}

.contactForm .MuiButton-root {
  width: 100%;
}

.contactForm .MuiTextField-root {
  width: 100%;
}

.ContactGreeting h2 {
  text-align: left;
  color: blue;
  margin-bottom: 20px;
  font-size: xxx-large;
}

.contactpre {
  padding: 15px;
  text-wrap: pretty;
  /* width: 199%; */
  max-width: 100%;
  text-align: justify;
}

/*  */
/* .ContactUS_MAP {
  max-width: 100%;
} */

/* .mapContainer {
  width: 100%;
  height: 600px;
  max-width: 600px;
} */
/*  */

@media only screen and (max-width: 768px) {
  .Contact {
    display: flex;
    flex-direction: column;
  }

  .ContactUS_RL {
    /* width: 100%;
    height: 100%; */
    display: flex;
    flex-direction: column;
  }

  .ContactLeft,
  .ContactRight {
    max-width: 100%;
  }

  .contactForm {
    width: 100%;
  }

  /* .ContactLeft {
  
    background-color: unset;
    border-top-right-radius: unset;
    border-bottom-left-radius: unset;
  }

  .ContactLeft .ContactGreeting {
    display: none;
  }

  .ContactLeft form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 95%;
  }

  /* .ContactRight {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;

  
  } */

  /* .ContactRight MapContainer {
    width: 300px;
  } */
}
