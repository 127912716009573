* {
  padding: 0;
  margin: 0;
}

.App {
  /* padding: 10px; */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex; /* Example: Use flexbox layout */
  flex-direction: column;
  justify-content: space-between; /* Example: Arrange children vertically */
  /* justify-content: space-between; */

  /* align-items: center;  */
  /* Example: Center children horizontally */
  /* justify-content: center;  */
  /* Example: Center children vertically */
  /* Example: Set a font family */
  font-family: Arial, sans-serif;
  /* padding: 10px; */
}

/* .App_Body {
  width: 100%;
  display: flex;
  flex-grow: 1;
} */

/* .p1div {
  width: 100%;
  height: 80vh;
} */

.NavDiv {
  width: 100%;
  height: 20%;
}

.FooterDiv {
  width: 100%;
  height: 50%;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .App {
    box-sizing: border-box;
    overflow-y: scroll;
  }

  .NavDiv {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10%;
    z-index: 1;
  }

  .App_Body {
    padding-top: 20%;
  }

  .FooterDiv {
    display: flex;
    box-sizing: border-box;
    height: 80vh;
    background-color: green;
  }
}
