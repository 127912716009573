.react-image {
  display: none;
}

.AboutUS {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: black; */
}

/* .beforeImgsDiv {
  display: flex;
  width: 100%; 
  height: 100vh;
  flex-direction: row;
} */

.beforeImgsDiv {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: row;
}

.TextTransitionDiv {
  display: flex;
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TextTransitionFixed,
.TextTransition {
  font-size: 92px;
}

.CarouselDiv {
  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  background-color: aqua; */
  box-sizing: border-box;
  display: flex;
  width: 50%;
  height: 100%;
  display: flex;
}

.beforeCarousel {
  flex-grow: 1;
  display: flex;
  width: 50vw;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.beforeCarouseImg {
  object-fit: cover;
  width: 50vw;
  height: 50vh;
}

/* .TextTransitionDiv {
  max-width: 50%;
  max-height: 100%;
  flex-grow: 1;
  text-align: center; 
  margin-bottom: 20px; 
  background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

/* .TextTransition {
  font-size: 2em; 
  color: #333; 
  font-weight: bold; 
} */

/* .beforeCarouselContainer {
  display: flex;
  max-width: 50%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
} */

/* .beforeCarouseImg {
  max-width: 100%;
  height: auto;
  object-fit: cover;
} */

/* .beforeImgsList {
  width: 60%; 
  height: 450px;
  margin-top: 20px; 
} */

.TimeLine {
  max-width: 90%;
}

.time {
  color: blue;
  font-family: "Times New Roman", Times, serif;
}

.AboutUS h2 {
  color: orange;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.TimelineContent {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  color: #5f6d7e;
}

.TimeLine {
  width: 100%;
  padding: 20px;
}

.auheader {
  font-weight: bold;
  padding: 25px;
}

.AUContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  justify-content: space-evenly;
}

.aucpre {
  width: 400px;
  height: 400px;
  text-wrap: pretty;
  white-space: none;
  font-size: 16px;
  color: black;
  text-justify: newspaper;
}

.auch {
  color: orange;
  font-family: "Courier New", Courier, monospace;
  font-size: xx-large;
}

.Img_AU {
  border-radius: 15px;
}

/*  */
.AUC1Left .AUC1Right {
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  */
/* The hero image */
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/bgimg1.jpg");

  /* Set a specific height */
  width: 100%;
  height: 200px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

/* Place text in the middle of the image */
.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hero-text h1 {
  /* font-family: "Courier New", Courier, monospace; */
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 768px) {
  .TimeLine {
    width: 90%;
  }
  .hero-text h1 {
    font-size: 24px; /* Adjust font sizes for smaller screens */
  }

  .TimeLine .ant-timeline-item-content {
    text-align: center; /* Center align timeline items on smaller screens */
  }

  .ant-image {
    display: none;
  }

  .react-image {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .TextTransitionDiv h2 {
    font-size: 12px;
  }

  .TextTransitionDiv h1 {
    font-size: 22px;
    color: blue;
  }
}
