.FAQMain {
  /* max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  display: flex;
  max-width: 100%;
  flex-direction: row;
}

.FAQLeft {
  max-width: 50%;
}

.FAQMain img {
  max-width: 100%;
  height: auto;
}

.FAQMain h2 {
  text-align: center;
  color: orange;
  margin-bottom: 20px; /* Add spacing below heading */
  font-size: xx-large;
}

/* .FAQMain Collapse {
  width: 90%;
} */

@media only screen and (max-width: 768px) {
  .FAQMain {
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }

  .FAQLeft {
    max-width: 100%;
  }

  .FAQMain img {
    max-width: 100%;
    height: auto;
  }
}
