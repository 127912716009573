* {
  padding: 0;
  margin: 0;
}

.Partners {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/partners/bg1.jpg");
  background-size: cover;
  background-position: center;
  border-bottom-right-radius: 500px;
}

.PartnerHeader {
  /* background-image: url("../../assets/partners/partnersbg1.jpg"); */
  /* background-size: 100% 100%;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center; */

  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-attachment: scroll; */

  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/partners/partnersbg1.jpg");
  /* Set a specific height */
  width: 100%;
  height: 250px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  object-fit: cover;
}

.PartnerHeader_label {
  /* transform: translateX(-50%);
  background-color: rgba(
    255,
    255,
    255,
    0.7
  );  */
  /* Adjust background color and opacity */
  /* padding: 10px 20px; */
  /* padding: 50px;
  border-radius: 10px;
  font-size: 18px;
  color: #333; 
  display: flex;
  align-self: center;
  justify-self: center;
  text-align: center; */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: "Montserrat", sans-serif;
}

.PartnerImg {
  width: 200px;
  height: 200px;
  padding: 20px;
}

.PartnersPreDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.partnersImg {
  display: block;
  max-width: 50%;
  height: 400px;
  margin-bottom: 10px;
}

.PartnersPre {
  width: 80%;
  text-wrap: wrap;
  text-align: center;
  padding: 15px;
  font-size: 22px;
  color: #333;
  font-family: 'Open Sans',sans-serif;
}

.partners_container {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 30px;
  grid-template-columns: auto auto auto;
}

.partners_card {
  width: 250px;
  height: 250px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* background-color: whitesmoke;
   opacity: 0.9; */
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.partners_card h3 {
  font-size: 1.2em;
  color: orange;
  margin-bottom: 10px;
}

.partners_card pre {
  font-size: 14px;
  color: #666;
  white-space: pre-wrap;
  text-align: justify;
}

.partner_logo {
  max-width: 100%;
  height: 80px;
  display: block;
  margin: 0 auto 10px; /* Center the logo */
  justify-self: left;
}

/*  */
.marquee_container {
  width: 100%;
}

/*  */

@media screen and (max-width: 768px) {
  .PartnersPreDiv {
    display: none;
  }
  .partners_container {
    /* width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: scroll; */
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap; /* Prevent cards from wrapping */
    width: 100%;
    overflow-x: unset; /* Enable horizontal scrolling */
    white-space: unset; /* Prevent line breaks */
  }

  .partners_card {
    width: 300px; /* Fixed width for each card */
  }
}
