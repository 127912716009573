/* * {
  padding: 0;
  margin: 0;
} */

.DrawerDiv {
  display: none;
}

.Navbar {
  /* position: relative;
  top: 0; */
  width: 100%;
  height: 100%;
  /* background-color: black; */
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
}
/* ################################################################ */
/*  */
.Navbar_Top {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: space-around;
  border-bottom-style: solid;
  border-color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

/*  */
.nbleft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #03477e;
}

.nbleftp1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #03477e;
}

.nbright {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #03477e;
}
/*  */

.socialmediaIcon {
  width: 50px;
  height: 50px;
}
/*  */
/* ################################################################### */
/*  */
.Navbar_Bottom {
  /* background-color: lightblue; */
  /* background-color: whitesmoke; */
  background-color: #03477e;
  display: flex;
  width: 100%;
  height: 50%;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
/*  */
.CompanyLogoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  width: auto;
  height: 35%;
}
/*  */
.logo {
  width: 50px;
  height: 50px;
  background-size: contain;
}

.CompanyName {
  font-family: "Roboto", sans-serif;
  color: white;
}
/*  */
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

/* svg {
  font-family: "Russo One", sans-serif;
  width: 450px;
  height: 50%;
} */
/* svg text {
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #365fa0;
  font-size: 22px;
} */
/* @keyframes stroke {
  0% {
    fill: rgba(72, 138, 204, 0);
    stroke: rgba(54, 95, 160, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(72, 138, 204, 0);
    stroke: rgba(54, 95, 160, 1);
  }
  80% {
    fill: rgba(72, 138, 204, 0);
    stroke: rgba(54, 95, 160, 1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(72, 138, 204, 1);
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
} */
/*  */
.NavbarMenu {
  /* background-color: #184a9a; */
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*  */
.Navbar_ul {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  list-style-type: none;
}

.MenuList {
  padding-left: 20px;
  padding-right: 20px;
}
.MenuLable {
  color: white;
  /* font-size: xx-large;
  font-weight: bolder; */
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.MenuLable:hover {
  color: lightblue;
}
/*  */
/* ###################################################################### */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  a {
    color: red;
  }

  .NavbarMenu {
    display: none;
  }

  .CompanyLogoDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: auto;
    height: 35%;
  }

  .CompanyName {
    text-wrap: wrap;
    font-size: 16px;
  }

  .Navbar_Top {
    display: none;
  }

  .mobileMenuSContainer {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    height: 100%;
  }

  .mobileMenuIcons {
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

  .mNavbarIconns {
    display: block;
    max-width: 24px;
    height: auto;
  }

  .DrawerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: auto;
  }

  .Navbar_Bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }

  .MenuIcon {
    color: white;
    /* max-width: 100%;
    height: auto; */
    height: 75px;
    width: 75px;
  }

  .DrawerMenu {
    list-style-type: none;
    padding: 0;
  }

  .DrawerMenu li {
    margin-bottom: 15px;
  }

  .DrawerMenu li a {
    color: #333;
    font-size: 32px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
