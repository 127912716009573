/* MedicalService.css */
.SubService {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Arial, sans-serif;
  color: #333;
}

.SubServiceThumbDiv {
  width: 100%;
  height: 25%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.ssthumb_img {
  text-align: center;
  max-width: 90%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
}

.SubServiceContent {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.SubServiceContent h2 {
  font-size: 24px;
  color: #0056a3;
  margin-bottom: 15px;
}

.SubServiceContent p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.SSCapabilities {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SSCapabilitie {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  flex: 1 1 45%;
  max-width: 50%;
}

.SSCapabilitie .SSheader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.SSCapabilitie .SSheader img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.SSCapabilitie h3 {
  font-size: 20px;
  color: #333;
}

.SSCapabilitie p {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
}

.CarouselContainerImgDIV {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CarouselContainerImg {
  max-width: 100%;
  height: 400px;
  object-fit: contain;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: #0056a3;
  font-size: 24px;
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: #0056a3;
}

@media only screen and (max-width: 768px) {
  /* MedicalService.css */
  .medical-service-container {
    font-family: Arial, sans-serif;
    color: #333;
  }

  .medical-service-thumb {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
  }

  .medical-service-thumb img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  .medical-service-content {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .medical-service-content h2 {
    font-size: 24px;
    color: #0056a3;
    margin-bottom: 15px;
  }

  .medical-service-content p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  .medical-service-capabilities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .medical-service-capability {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    flex: 1 1 45%;
    max-width: 45%;
  }

  .medical-service-capability-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .medical-service-capability-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .medical-service-capability-title {
    font-size: 20px;
    color: #333;
  }

  .medical-service-capability-description {
    font-size: 14px;
    line-height: 1.6;
    color: #666;
  }

  .medical-service-carousel-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .medical-service-carousel {
    margin-top: 20px;
  }

  .medical-service-carousel .slick-prev,
  .medical-service-carousel .slick-next {
    color: #0056a3;
    font-size: 24px;
  }

  .medical-service-carousel .slick-dots li.slick-active button {
    background-color: #0056a3;
  }

  /* Media Queries for Mobile Devices */
  /* @media only screen and (max-width: 768px) {
    .medical-service-content {
      padding: 15px;
    }

    .medical-service-content h2 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .medical-service-content p {
      font-size: 14px;
      margin-bottom: 15px;
    }

    .medical-service-capabilities {
      flex-direction: column;
    }

    .medical-service-capability {
      flex: 1 1 100%;
      max-width: 100%;
      margin: 10px 0;
    }

    .medical-service-capability-header {
      flex-direction: column;
      align-items: flex-start;
    }

    .medical-service-capability-icon {
      width: 35px;
      height: 35px;
      margin-bottom: 5px;
    }

    .medical-service-capability-title {
      font-size: 18px;
      margin-bottom: 5px;
    }

    .medical-service-capability-description {
      font-size: 13px;
    }

    .medical-service-carousel-img {
      height: 250px;
    }

    .medical-service-carousel .slick-prev,
    .medical-service-carousel .slick-next {
      font-size: 20px;
    }
  } */
}
