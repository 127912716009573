/* Reset styles */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* Base styles
body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  width: 100vw;
  height: 100vh;
} */

/* Other global styles */

/* body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: aqua;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
