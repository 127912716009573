* {
  padding: 0;
  margin: 0;
}

.Landing {
  /* background-image: url("../../assets/bgimg1.jfif");
  background-repeat: no-repeat;
  background-size: cover; */
  width: 100%;
  height: 75%;
}
/*  */
.carouselDivContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carouselIMG {
  width: 100%;
  height: 90vh;
  object-fit:cover;
}

/* .legend {
  
} */


.legend:hover {
  opacity: 0.5;
}

.Slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: #f5f5f5; */
}
/* Slide content */
.slide-content {
  max-width: 800px; /* Adjust max-width as needed */
  padding: 20px;
}

/* Headline */
.slide-content h1 {
  font-size: 3rem; /* Adjust font size as needed */
  /* color: #333; */
  color: white;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

/* Description */
.slide-content p {
  font-size: 1.2rem; /* Adjust font size as needed */
  /* color: #666; */
  color: white;
  margin-bottom: 30px;
  font-family: "Open Sans", sans-serif;
}

/* .WelcomDiv {
  position: relative;
  top: 50%;
  left: 50%;
  background-color: aqua;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 45%;
  height: 40%;
  padding: 1%;
  opacity: 0.6;
} */

/* .wecomeBody {
  justify-content: center;
  text-align: justify;
  text-wrap: pretty;
} */
