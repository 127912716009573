.Testimonial {
  width: 100%;
  height: 100%;
}

.TestimonialContainer {
  max-width: 100%;
  display: flex;
  justify-content: center; /* Center carousel items horizontally */
  align-items: center; /* Center carousel items vertically */
  height: 160px;
  background-color: #364d79;
  color: #fff;
}

.TestimonialContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Align content vertically within container */
  max-width: 100%;
  padding: 20px;
}

.TestimonialBody {
  flex: 1; /* Take up remaining space */
  max-width: 60%; /* Adjust max-width as needed */
}

.TestimonialPerson {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px; /* Adjust spacing between body and person */
}

.TestimonialHeader {
  text-align: left; /* Adjust text alignment as needed */
  margin-bottom: 10px;
}

.TestimonialMessage {
  text-align: justify;
  margin: 0;
  /* max-height: 100%; */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .TestimonialContent {
    flex-direction: column; /* Stack content vertically on smaller screens */
  }

  .TestimonialPerson {
    margin-left: 0; /* Remove margin on smaller screens */
    margin-top: 10px; /* Add top margin for spacing */
  }

  .TestimonialBody,
  .TestimonialPerson {
    max-width: 100%; /* Full width on smaller screens */
  }
}
